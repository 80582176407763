export default defineNuxtRouteMiddleware((to) => {
    const authenticated = useCookie('authenticated')

    if (authenticated.value) {
        authenticated.value = true
    }

    if (authenticated.value && to?.name === 'login') {
        return navigateTo('/manuals')
    }

    if (!authenticated.value && to?.name !== 'login') {
        abortNavigation()

        return navigateTo('/login')
    }
})